import "promise-polyfill/src/polyfill";
import objectFitImages from "object-fit-images";
import "./scss/site.scss";
import MobileMenu from "./js/mobileMenu";
import Typewriter from "typewriter-effect/dist/core";
import SmoothScroll from "smooth-scroll";

objectFitImages();

//MOBILE MENU

new MobileMenu({
    id: "mobile-menu",
});

//ON SCROlL ADD CLASS

window.addEventListener("scroll", () => {
    if (window.scrollY) {
        document.body.classList.add("scrolling");
    } else {
        document.body.classList.remove("scrolling");
    }
});

//HERO TYPEWRITER

const heroTypewriter = document.getElementById("hero-typewriter");
const strings = heroTypewriter.dataset.strings.split(",");
const options = {
    loop: true,
    cursor: "",
    delay: 75,
    autoStart: true,
};

const typewriter = new Typewriter(heroTypewriter, options);
typewriter
    .pauseFor(300)
    .typeString(strings[0])
    .pauseFor(600)
    .typeString("<br/>" + strings[1])
    .pauseFor(600)
    .typeString(strings[2] ? "<br/>" + strings[2] : "")
    .pauseFor(5000)
    .start();

var scroll = new SmoothScroll('a[href*="#"]', {
    header: "#header",
    topOnEmptyHash: true,
    speed: 500,
    offset: 30,
    speedAsDuration: false,
    easing: "easeInOutCubic",
});
