const MobileMenu = class {
    constructor({ id }) {
        this.id = id;

        if (document.readyState === "complete" || document.readyState === "interactive") {
            this.onDomContentLoaded();
        } else {
            document.addEventListener("DOMContentLoaded", this.onDomContentLoaded.bind(this));
        }
    }

    onDomContentLoaded() {
        this.toggler = document.querySelector(`.${this.id}__toggler`);
        this.content = document.querySelector(`.${this.id}__content`);

        if (this.toggler) {
            this.toggler.addEventListener("click", this.eventToggle.bind(this));
        }
    }

    eventToggle(e) {
        if ("key" in e) {
            if (e.key === "Escape" || e.key === "Esc" || e.keyCode === 27) {
                this.closeMenu();
            }
        } else {
            this.toggleMenu();
        }
    }

    toggleMenu() {
        this.content.classList.toggle("hidden");
        this.toggler.classList.toggle("active");
    }

    openMenu() {
        this.content.classList.remove("hidden");
        this.toggler.classList.add("active");
    }

    closeMenu() {
        this.content.classList.add("hidden");
        this.toggler.classList.remove("active");
    }
};

export default MobileMenu;
